import React from 'react';
import vrqWhiteLogo from 'assets/img/vrq/VRQ-White.png';
import logoRET from 'assets/img/ret-white-logo.svg';
import logoDesktopPoweredBy from 'assets/img/ret/rodeo-logistics-powered-by-desktop-logo.svg';
import logoMobilePoweredBy from 'assets/img/ret/rodeo-logistics-powered-by-mobile-logo.svg';
import { getTheme } from 'helpers';
import { isMobileDevice } from 'helpers';

const HeaderLogo = () => {
  const theme = getTheme();

  return theme === 'ret' ? (
    <span>
      <img alt="RET" src={logoRET} className="wcra-logo ret-logo" />
      {isMobileDevice() ? (
        <img
          alt="Powered by rodeo logistics"
          src={logoMobilePoweredBy}
          className="powered-by-logo"
        />
      ) : (
        <img
          alt="Powered by rodeo logistics"
          src={logoDesktopPoweredBy}
          className="powered-by-logo"
        />
      )}
    </span>
  ) : (
    <img alt="VRQ" src={vrqWhiteLogo} style={{ width: '90px' }} />
  );
};

export default HeaderLogo;
