// @flow
import React, { PureComponent, createContext } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import moment from 'moment';
import { fetchUserByToken } from 'actions/user';
import { GET_USER_PROFILE } from 'queries/GetUserProfile';
import { getValueLabelObject } from 'helpers';
import type { ValueLabelType } from 'helpers';
import type { EventType } from 'models/Event';
import type { Node, AbstractComponent } from 'react';
import { getNestedProperty, isUserYouth, isUserFemale } from 'helpers';
import { isYouthEvent } from '../helpers/isYouthEvent';

export type ContextValue = {|
  ERAUID: string,
  athleteFullName: string,
  isYouthAthlete: ?boolean,
  isFemaleAthlete: ?boolean,
  getUserAthlete: Function,
  getYouthAthletesLabel: Function,
  selectAthlete: Function,
  getUserCreatedEventPerformances: Function,
  resetSelectedAthlete: Function,
|};

export const AthletesContext = createContext<ContextValue>({
  ERAUID: '',
  athleteFullName: '',
  isYouthAthlete: null,
  isFemaleAthlete: null,
  getUserAthlete: () => {},
  getYouthAthletesLabel: () => {},
  selectAthlete: async () => {},
  getUserCreatedEventPerformances: async () => {},
  resetSelectedAthlete: () => {},
});

const { Provider, Consumer } = AthletesContext;

const mapStateToProps = (state) => {
  const { token } = state.auth.get('data').authPayload;
  return {
    event: state.event.get('data'),
    user: state.user.get('data'),
    userToken: token && token.refreshToken,
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchUser: (token) => dispatch(fetchUserByToken(GET_USER_PROFILE, token)),
});

type SelectedAthleteProviderProps = {|
  event: EventType,
  user: Object,
  userToken: string,
  children: Node,
  fetchUser: (string) => Promise<Object>,
|};

type SelectedAthleteProviderState = {|
  ERAUID: string,
  athleteFullName: string,
  isYouthAthlete: ?boolean,
  isFemaleAthlete: ?boolean,
|};

class SelectedAthleteProviderPropsBase extends PureComponent<
  SelectedAthleteProviderProps,
  SelectedAthleteProviderState,
> {
  state = {
    ERAUID: '',
    athleteFullName: '',
    isYouthAthlete: null,
    isFemaleAthlete: null,
  };

  componentDidMount() {
    this.setFirstSelectedAthlete();
  }

  componentDidUpdate(prevProps: SelectedAthleteProviderProps) {
    const { event } = this.props;
    const { event: prevEvent } = prevProps;

    if (event && prevEvent.id && event.id !== prevEvent.id) {
      this.setFirstSelectedAthlete();
    }
  }

  setFirstSelectedAthlete = async () => {
    const { userToken, fetchUser, event, user } = this.props;
    if (!userToken) return;

    const userResponse = await fetchUser(userToken);

    const { firstName, lastName, isAthlete, isParent, ERAUID, youthAthletes } =
      userResponse || user;

    const isYouthDivision = isYouthEvent(event.EventRank);
    const isParentAthlete = isParent && isAthlete;
    const selectedERAUID =
      youthAthletes &&
      youthAthletes.length &&
      (isYouthDivision || !isParentAthlete)
        ? youthAthletes[0].ERAUID
        : ERAUID;

    const isYouthAthlete =
      getNestedProperty('length', youthAthletes) &&
      (isYouthDivision || !isParentAthlete);

    const athleteGender =
      isYouthAthlete && youthAthletes && youthAthletes.length
        ? youthAthletes[0].Gender
        : user.Gender;

    const isFemaleAthlete = isUserFemale(athleteGender);

    // eslint-disable-next-line
    this.setState({
      ERAUID: selectedERAUID,
      athleteFullName:
        (isAthlete || !isParent) && !isYouthEvent(event.EventRank)
          ? `${firstName} ${lastName}`
          : youthAthletes[0] && youthAthletes[0].FullName,
      isYouthAthlete,
      isFemaleAthlete,
    });
  };

  methods = {
    resetSelectedAthlete: () => {
      this.setState({
        ERAUID: '',
        athleteFullName: '',
        isYouthAthlete: null,
        isFemaleAthlete: null,
      });
    },
    getUserCreatedEventPerformances: () => {
      const { event } = this.props;
      if (!event.dateStart || !event.dateEnd) return [];

      const performances = [];
      let nextDate = moment(event.dateStart);
      let performanceUID = 0;
      const dateEnd = moment(event.dateEnd);

      // $FlowFixMe
      while (nextDate <= dateEnd) {
        performances.push({
          PerformanceUID: performanceUID,
          isUpcoming: true,
          PerformanceDate: moment(nextDate).format('YYYY-MM-DD'),
        });
        nextDate = moment(nextDate).add(1, 'days');
        performanceUID += 1;
      }

      return performances;
    },
    getUserAthlete: (): ValueLabelType => {
      const { user } = this.props;
      const { DOB, Gender } = user;
      const isYouthAthlete = isUserYouth(DOB);
      const isFemaleAthlete = isUserFemale(Gender);
      return {
        ...getValueLabelObject(
          user.ERAUID,
          `${user.firstName} ${user.lastName}`,
        ),
        isYouthAthlete,
        isFemaleAthlete,
      };
    },
    getYouthAthletesLabel: (): ValueLabelType[] => {
      const { event, user } = this.props;

      const youthAthletes = user.youthAthletes.map((athlete) => {
        const isFemaleAthlete = isUserFemale(athlete.Gender);
        const { DOB } = athlete;
        const isYouthAthlete = isUserYouth(DOB);

        return {
          value: athlete.ERAUID,
          label: athlete.FullName,
          isYouthAthlete: isYouthAthlete,
          isFemaleAthlete,
        };
      });

      if (isYouthEvent(event.EventRank) || !user.isAthlete) {
        return youthAthletes;
      }

      let parentAthlete = [this.methods.getUserAthlete()];
      parentAthlete[0].isFemaleAthlete = isUserFemale(user.Gender);

      return [].concat(parentAthlete, youthAthletes);
    },

    selectAthlete: async ({
      value: ERAUID = null,
      label = null,
      isYouthAthlete = false,
      isFemaleAthlete = false,
    }: Object): Promise<void> => {
      return this.setState(() => ({
        ERAUID,
        athleteFullName: label,
        isYouthAthlete,
        isFemaleAthlete,
      }));
    },
  };

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          ...this.methods,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export const SelectedAthleteProvider = compose(
  // $FlowFixMe
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
)(SelectedAthleteProviderPropsBase);

export const withSelectedAthletes = (
  ComposedComponent: AbstractComponent<any>,
) => {
  return function WithSelectedAthletes(props: any) {
    return (
      <Consumer>
        {(contextValues) => <ComposedComponent {...props} {...contextValues} />}
      </Consumer>
    );
  };
};
